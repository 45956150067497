import logo from './logo.svg';
import icon from './icon.png';
import rsm from './resume/Jay_Pinnamaneni_NOV_2021.pdf'
import './App.css';
// import pdf from 

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <div> */}
          
        {/* </div> */}
        <p>
          Thanks For Visiting! Related Links Below.
        </p>

        <a
          className="App-link"
          // href={'./resume/Jay_Pinnamaneni_NOV_2021.pdf'}

          href={"https://drive.google.com/file/d/19KZ_3v8oyPRs-_bf1E4awHRB5JrFbg6P/view?usp=sharing"}
          target="_blank"
          rel="noopener noreferrer"
        >
          ResumeNOV2021
        </a>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/jaypinnamaneni/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn Profile
        </a>
        <a
          className="App-link"
          href="https://drive.google.com/drive/folders/1HXq4YaeQH0w1qX8Fc55aHYOELaaaO7-Q?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          FastNFrosty_FALL2021_Georgia_Tech
        </a>
        <a
          className="App-link"
          href="https://www.youtube.com/channel/UCaY_EXoHxWROBh6NlmydIpA/videos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Autonomous_CV_Embedded_University_of_Michigan
        </a>
        <a
          className="App-link"
          href="https://www.credential.net/152f3780-9049-48e8-85c2-aea95c6e206a#gs.h6rwb1"
          target="_blank"
          rel="noopener noreferrer"
        >
          CSEP Credential
        </a>
        <a
          className="App-link"
          href="https://www.hackerrank.com/certificates/c6f7e5a78140"
          target="_blank"
          rel="noopener noreferrer"
        >
          HackerrankCerts
        </a>
        <a
          className="App-link"
          href="https://www.credly.com/badges/f4c72ef5-a847-495e-b244-d30c7c456ef4"
          target="_blank"
          rel="noopener noreferrer"
        >
          ScrumCert
        </a>
        <a
          className="App-link"
          href="http://www-personal.umich.edu/~pinnamja"
          target="_blank"
          rel="noopener noreferrer"
        >
          legacy_UofM_site_http_only
        </a>
        <img src = {icon} alt = "NOV 2021"/>
        </header>
        

      
    </div>
  );
}

export default App;
